
import {computed, defineComponent, onMounted, onUnmounted, ref} from "vue"
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import HelpCategoriesDropdown from "@/components/dropdown/HelpCategoriesDropdown.vue";
import {helpArticle} from "@/components/help/types";
import {useAbility} from "@casl/vue";
import Swal from "sweetalert2";
import tinyMceEditor from "@/components/help/misc/tinyMceEditor.vue";

export default defineComponent({
  name: "edit-help-article",
  components: {
    tinyMceEditor,
    HelpCategoriesDropdown,
    LoadingSpinner,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const loading = ref<boolean>(false);
    const isSaving = ref<boolean>(false);
    const helpArticle = computed<helpArticle|null>(
        () => store.getters['HelpModule/getSingleArticle']
    );
    function fetchArticle(articleId) {
      loading.value = true;
      store.dispatch("HelpModule/fetchSingleArticle", articleId)
          .finally(() => loading.value = false);
    }
    function handleBackButton() {
      router.push('/help');
    }
    onMounted(() => {
      setCurrentPageTitle("Edit article");
      const articleId = route.params.id as string;
      if(articleId) {
        fetchArticle(articleId);
      }
    });
    onUnmounted(() => {
      store.dispatch("HelpModule/clearEditArticleState");
    })
    function saveArticle() {
      isSaving.value = true;
      store.dispatch("HelpModule/saveArticle", helpArticle.value)
          .finally(() => {
             Swal.fire({
                toast: true,
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
                title: "Article saved successfully",
                position: "top-right",
                timer: 500
             })
             .then(() => {
               isSaving.value = false;
               router.push('/help');
             })
          })
    }
    return {
      saveArticle,
      handleBackButton,
      isSaving,
      loading,
      helpArticle,
    }
  }
});

